import React from 'react';
import Helmet from 'react-helmet';
import PropTypes from 'prop-types';

const SEO = ({ title, description }) => {
  const Title = `Regis Photography — ${title}`;
  const protocol = window.location.protocol;
  const hostname = window.location.hostname;

  return (
    <Helmet>
      <title>{Title}</title>
      <meta name="description" content={description} />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="website" />
      <meta property="og:title" content="Regis Biron — Photography" />
      <meta
        property="og:description"
        content="Most of the time I'm a designer, but sometimes I take photos."
      />
      <meta property="og:url" content={`${protocol}//${hostname}/`} />
      <meta property="og:site_name" content="Regis Biron — Photography" />
      <meta
        property="og:image"
        content={`${protocol}//${hostname}/assets/img/share/og-share.jpg`}
      />
      <meta property="og:image:width" content="1200" />
      <meta property="og:image:height" content="630" />
      <meta name="twitter:url" content={`${protocol}//${hostname}/`} />
      <meta name="twitter:title" content="Regis Photography" />
      <meta
        name="twitter:description"
        content="Most of the time I'm a designer, but sometimes I take photos."
      />
      <meta
        name="twitter:image"
        content={`${protocol}//${hostname}/assets/img/share/twitter-share.jpg`}
      />
    </Helmet>
  );
};

SEO.defaultProps = {
  title: `Regis Photography`,
  description: `Most of the time I'm a designer, but sometimes I take photos.`,
};

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};

export default SEO;
