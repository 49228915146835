function importAll(r) {
  return r.keys().map(r);
}

const Banff = importAll(
  require.context('./assets/img/banff-jasper', false, /\.(png|jpe?g|svg)$/)
);

const Italy = importAll(
  require.context('./assets/img/italy', false, /\.(png|jpe?g|svg)$/)
);

const Scotland = importAll(
  require.context('./assets/img/scotland', false, /\.(png|jpe?g|svg)$/)
);

const Denmark = importAll(
  require.context('./assets/img/denmark', false, /\.(png|jpe?g|svg)$/)
);

const Malta = importAll(
  require.context('./assets/img/malta', false, /\.(png|jpe?g|svg)$/)
);

const NE = importAll(
  require.context('./assets/img/new-england', false, /\.(png|jpe?g|svg)$/)
);

const Config = {
  destinations: [
    {
      title: 'Banff & Jasper',
      description: 'Lakes more blue than your local swimming pool.',
      imagePath: 'Banff Jasper',
      images: Banff,
      path: '/banff-jasper',
    },
    {
      title: 'Scotland',
      description: 'Bask in the breathtaking landscape of the highlands.',
      imagePath: 'Scotland',
      images: Scotland,
      path: '/scotland',
    },
    {
      title: 'Italy',
      description:
        'Turns out Balzano is more interesting than The Colosseum and pizza.',
      imagePath: 'Italy',
      images: Italy,
      path: '/italy',
    },
    {
      title: 'Denmark',
      description: 'Beautifully Scandanavian.',
      imagePath: 'Denmark',
      images: Denmark,
      path: '/denmark',
    },
    {
      title: 'Malta',
      description: 'They filmed Game of Thrones here.',
      imagePath: 'Malta',
      images: Malta,
      path: '/malta',
    },
    {
      title: 'New England',
      description: 'Hidden Gems with Unpredictable Weather.',
      imagePath: 'New England',
      images: NE,
      path: '/new-england',
    },
  ],
};

export default Config;
