import React, { useContext, useEffect, useState } from 'react';
import { HoverContext } from '../../context/HoverContext';
import className from '../../utils/className';
import styles from './footer.css';

function Footer() {
  const events = useContext(HoverContext);
  const { active } = events.hoverEvents;
  const [date, setDate] = useState();

  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);

  return (
    <footer {...className(styles.wrapper, active && styles.active)}>
      <div className={styles.content}>
        <span className={styles.copyright}>
          &copy; Regis Biron {date}. Type from{' '}
          <a
            target="_blank"
            rel="noopener noreferrer nofollow"
            href="http://velvetyne.fr/"
          >
            Velvetyne
          </a>
          .
        </span>
        <ul className={styles.list}>
          <li className={styles.item}>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://www.instagram.com/regisbiron/"
            >
              Instagram
            </a>
          </li>
          <li className={styles.item}>
            <a
              target="_blank"
              rel="noopener noreferrer nofollow"
              href="https://vsco.co/regisbiron/gallery"
            >
              VSCO
            </a>
          </li>
        </ul>
      </div>
    </footer>
  );
}

export default Footer;
