import React, { useState, useContext, useEffect } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import className from '../../utils/className';
import PropTypes from 'prop-types';
import { HoverContext } from '../../context/HoverContext';
import { AccordionItem, AccordionItemMobile } from '../../components';
import styles from './accordion.css';

function Accordion({ title, images, path }) {
  const [isOpen, setOpen] = useState(() => {
    const showAccordion = localStorage.getItem(title);
    return JSON.parse(showAccordion) ?? true;
  });
  const events = useContext(HoverContext);
  const mobile = isMobile || isTablet;

  useEffect(() => {
    localStorage.setItem(title, JSON.stringify(isOpen));
  }, [isOpen]);

  return (
    <div
      {...className(
        styles.wrapper,
        events.hoverEvents.active && styles.active,
        isOpen && styles.wrapperOpen
      )}
    >
      <button
        onClick={() => {
          setOpen(!isOpen);
        }}
        {...className(styles.title, isOpen && styles.buttonOpen)}
      >
        {title}
      </button>
      <div {...className(styles.content, isOpen && styles.contentOpen)}>
        <ul className={styles.list}>
          {mobile
            ? images &&
              images.map((image, index) => (
                <AccordionItemMobile
                  key={index.toString()}
                  image={image}
                  title={title}
                  index={index}
                  path={path}
                />
              ))
            : images &&
              images.map((image, index) => (
                <AccordionItem
                  key={index.toString()}
                  image={image}
                  title={title}
                  index={index}
                  path={path}
                />
              ))}
        </ul>
      </div>
    </div>
  );
}

Accordion.propTypes = {
  title: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  images: PropTypes.array,
};

export default Accordion;
