import React, {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { Transition } from 'react-transition-group';
import { TweenMax, Power3 } from 'gsap/all';
import hexSorter from 'hexsorter';
import { HoverContext } from '../../context/HoverContext';
import { Palette } from 'color-thief-react';
import className from '../../utils/className';
import styles from './photoGradient.css';

let newColors;

function Loading() {
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(true);
    }, 1000);
    return () => clearTimeout(timer);
  }, []);

  return (
    <div {...className(styles.loading, isLoading && styles.isLoading)}>
      Loading...
    </div>
  );
}

function PhotoGradient({ children }) {
  const events = useContext(HoverContext);
  const bgRef = useRef(null);
  const { active, img } = events.hoverEvents;
  const { x, y } = events.moveEvents;

  useLayoutEffect(() => {
    TweenMax.to(bgRef.current, 0.5, {
      '--mouseX': `${x}%`,
      '--mouseY': `${y}%`,
      ease: Power3.easeOut,
    });
  }, [bgRef, x, y, active]);

  return (
    <div className={styles.wrapper}>
      <div {...className(styles.bg, active && styles.active)}>
        <div className={styles.move} ref={bgRef}>
          <Transition
            unmountOnExit
            in={active}
            onEnter={(node) => {
              TweenMax.set(node, { autoAlpha: 0 });
            }}
            onEntering={(node) => {
              TweenMax.to(node, 0.2, {
                autoAlpha: 1,
                ease: Power3.easeIn,
              });
            }}
            onExit={(node) => {
              TweenMax.set(node, { autoAlpha: 0 });
            }}
            timeout={{ enter: 200, exit: 0 }}
          >
            <div>
              {img && active && (
                <Palette
                  src={img}
                  crossOrigin="anonymous"
                  format="hex"
                  colorCount={8}
                >
                  {({ data, loading }) => {
                    if (loading) return <Loading />;
                    if (data) {
                      const colors = [data[0], data[5], data[7]];
                      newColors = hexSorter.sortColors(
                        colors,
                        'mostBrightColor'
                      );
                    }
                    return (
                      <div
                        style={{
                          background: `radial-gradient(
                        60vw circle at var(--mouseX) var(--mouseY), 
                        ${newColors[0]}, 
                        ${newColors[1]},   
                        ${newColors[2]})`,
                        }}
                        className={styles.gradient}
                      ></div>
                    );
                  }}
                </Palette>
              )}
            </div>
          </Transition>
        </div>
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

export default PhotoGradient;
