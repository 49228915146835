import React, { Component } from 'react';
import { HoverContext } from '../context/HoverContext';

class HoverProvider extends Component {
  state = {
    hoverEvents: {},
    moveEvents: {},
  };

  setHoverEvents = (hoverEvents) => {
    this.setState(() => ({ hoverEvents }));
  };

  updateMoveEvents = (moveEvents) => {
    this.setState(() => ({ moveEvents }));
  };

  render() {
    const { hoverEvents, moveEvents } = this.state;
    const { setHoverEvents, updateMoveEvents } = this;

    return (
      <HoverContext.Provider
        value={{
          hoverEvents,
          setHoverEvents,
          moveEvents,
          updateMoveEvents,
        }}
      >
        {this.props.children}
      </HoverContext.Provider>
    );
  }
}

export default HoverProvider;
