import React, { useContext, useEffect, useState } from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import { HoverContext } from '../../context/HoverContext';
import { useHover } from '../../hooks';
import className from '../../utils/className';
import styles from './header.css';

function Header() {
  const events = useContext(HoverContext);
  const [hoverRef, isHovered] = useHover();
  const [toggle, setToggle] = useState(false);
  const { active } = events.hoverEvents;
  const mobile = isTablet || isMobile ? true : false;

  useEffect(() => {
    if (mobile) {
      let test = toggle;
      const interval = setInterval(() => {
        test = !test;
        setToggle(test);
      }, 3000);
      return () => clearInterval(interval);
    } else {
      return;
    }
  }, [mobile]);

  return (
    <header
      {...className(styles.wrapper, active && styles.active)}
      ref={hoverRef}
      role="heading"
    >
      <div className={styles.content}>
        {mobile && (
          <h1 className={styles.mobileTitle}>
            {toggle ? (
              <span>
                Most of the time I'm a{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href="https://regis.digital"
                >
                  designer
                </a>{' '}
                though.
              </span>
            ) : (
              <span>Hi, I'm Regis and I take photos...</span>
            )}
          </h1>
        )}
        {!mobile && (
          <div className={styles.title}>
            {isHovered ? (
              <span>
                Most of the time I'm a{' '}
                <a
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                  href="https://regis.digital"
                >
                  designer
                </a>{' '}
                though.
              </span>
            ) : (
              <span>Hi, I'm Regis and I take photos...</span>
            )}
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
