import React from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';
import { TweenMax, Power3 } from 'gsap';
import Config from '../../config';
import { Home, NoMatch, Photo } from '../../pages';
import { Switch, Route } from 'react-router-dom';
import HoverProvider from '../../providers/HoverProvider';

function Routes() {
  return (
    <>
      <HoverProvider>
        <Route
          render={({ location }) => {
            const { key, pathname } = location;

            return (
              <TransitionGroup component={null}>
                <Transition
                  unmountOnExit
                  key={key}
                  appear={true}
                  onEnter={(node) => {
                    TweenMax.set(node, { autoAlpha: 0 });
                  }}
                  onEntered={(node) => {
                    if (pathname === '/') {
                      window.scrollTo(
                        0,
                        location.state !== undefined
                          ? location.state.scrollY
                          : 0
                      );
                    }
                    TweenMax.to(node, 0.5, {
                      autoAlpha: 1,
                      ease: Power3.easeIn,
                    });
                  }}
                  onExit={(node) => {
                    TweenMax.to(node, 0.5, {
                      autoAlpha: 0,
                      ease: Power3.easeOut,
                    });
                  }}
                  timeout={{ enter: 500, exit: 500 }}
                >
                  <Switch location={location}>
                    <Route exact path="/" render={() => <Home />} />
                    {Config.destinations.map((place) => (
                      <Route
                        key={place.path}
                        path={place.path}
                        render={() => <Photo {...place} />}
                      />
                    ))}
                    <Route component={NoMatch} />
                  </Switch>
                </Transition>
              </TransitionGroup>
            );
          }}
        />
      </HoverProvider>
    </>
  );
}

export default Routes;
