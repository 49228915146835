import React, { useContext, useState, useEffect, useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { isMobile, isTablet } from 'react-device-detect';
import { SEO } from '../../components';
import { HoverContext } from '../../context/HoverContext';
import { Palette } from 'color-thief-react';
import className from '../../utils/className';
import PropTypes from 'prop-types';
import styles from './photo.css';

function Photo({ title, description, images }) {
  const location = useLocation();
  const events = useContext(HoverContext);
  const { active } = events.hoverEvents;
  const [count, setCount] = useState(0);
  const mobile = isMobile || isTablet;
  const scrollY = location.state !== undefined ? location.state.scrollY : 0;

  const refs = useRef([]);

  const style = {
    '--vh': `${window.innerHeight * 0.01}px`,
  };

  useEffect(() => {
    setCount(location.state !== undefined ? location.state.photoIndex : 1);

    return () => {
      setCount(0);
    };
  }, []);

  useEffect(() => {
    if (active) {
      events.setHoverEvents({ active: false, img: '' });
    }
  }, [active]);

  return (
    <div
      style={mobile ? style : null}
      {...className(styles.wrapper, mobile && styles.isMobile)}
    >
      <SEO title={title} description={description} />
      <ul className={styles.list}>
        {images.map((image, index) => (
          <li
            key={index}
            {...className(styles.item, index === count && styles.active)}
          >
            <Palette
              src={image.placeholder}
              crossOrigin="anonymous"
              format="hex"
              colorCount={3}
            >
              {({ data, loading }) => {
                if (loading) return;
                return (
                  <span
                    style={{
                      '--photo-primary-color': data[0],
                      '--photo-secondary-color': data[1],
                      '--photo-tertiary-color': data[2],
                    }}
                    className={styles.container}
                  >
                    <Link
                      className={styles.link}
                      to={{
                        pathname: '/',
                        state: {
                          scrollY,
                        },
                      }}
                    >
                      <span className={styles.linkText}>Exit to Home</span>
                    </Link>

                    <span className={styles.bg}>
                      <button
                        className={styles.button}
                        tabIndex={0}
                        ref={(element) => {
                          refs.current[index] = element;
                        }}
                        onClick={() =>
                          setCount(count < images.length - 1 ? count + 1 : 0)
                        }
                      >
                        <img
                          {...className(styles.img, 'lazyload')}
                          style={{
                            height: `clamp(0vh, ${Math.floor(
                              (image.height / image.width) * 100
                            )}vw, 70vh)`,
                          }}
                          data-sizes="auto"
                          src={image.placeholder}
                          data-srcset={image.srcSet}
                          alt={title}
                        />
                      </button>
                    </span>
                  </span>
                );
              }}
            </Palette>
          </li>
        ))}
      </ul>
    </div>
  );
}

Photo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.array,
};

export default Photo;
