import React, { useState, useEffect } from 'react';
import useIsMounted from 'ismounted';
import { Link } from 'react-router-dom';
import { Palette } from 'color-thief-react';
import className from '../../utils/className';
import styles from './accordionItemMobile.css';

function AccordionItemMobile({ image, title, path, index }) {
  const [scrollY, setScrollY] = useState(0);
  const [ticking, setTicking] = useState(false);
  const isMounted = useIsMounted();

  const listener = () => {
    if (isMounted.current) {
      if (!ticking) {
        window.requestAnimationFrame(function () {
          console.log('test');
          setScrollY(window.scrollY);
          setTicking(false);
        });
      }

      setTicking(true);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
      cancelAnimationFrame(window);
    };
  }, []);

  return (
    <li className={styles.item} data-index={index}>
      <Palette
        src={image.placeholder}
        crossOrigin="anonymous"
        format="rgbString"
        colorCount={2}
      >
        {({ data, loading }) => {
          if (loading) return;
          return (
            <div
              style={{ backgroundColor: data[0] }}
              className={styles.content}
            >
              <Link
                className={styles.link}
                role="link"
                to={{
                  pathname: path,
                  state: {
                    photoIndex: index,
                    scrollY: scrollY,
                  },
                }}
              >
                <img
                  {...className(styles.img, 'lazyload')}
                  data-sizes="auto"
                  src={image.placeholder}
                  data-srcset={image.srcSet}
                  alt={title}
                />
              </Link>
            </div>
          );
        }}
      </Palette>
    </li>
  );
}

export default AccordionItemMobile;
