import React from 'react';
import { isMobile, isTablet } from 'react-device-detect';
import {
  Accordion,
  PhotoGradient,
  Header,
  Footer,
  SEO,
} from '../../components';
import Config from '../../config';
import styles from './home.css';
import { withRouter } from 'react-router-dom';

function Home() {
  const mobile = isMobile || isTablet;

  return (
    <div className={styles.wrapper}>
      <SEO title="Home" />
      <Header />
      {!mobile ? (
        <PhotoGradient>
          {Config.destinations.map((place, index) => (
            <Accordion
              key={index}
              title={place.title}
              images={place.images}
              path={place.path}
            />
          ))}
        </PhotoGradient>
      ) : (
        <>
          {Config.destinations.map((place, index) => (
            <Accordion
              key={index}
              title={place.title}
              images={place.images}
              path={place.path}
            />
          ))}
        </>
      )}
      <Footer />
    </div>
  );
}

export default withRouter(Home);
