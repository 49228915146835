import { hot } from 'react-hot-loader/root';
import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import './styles/styles.css';
import { Routes } from './containers';
import 'lazysizes';
import 'lazysizes/plugins/respimg/ls.respimg.js';

const App = () => (
  <Router>
    <Routes />
  </Router>
);

export default hot(App);
