import React, { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import useIsMounted from 'ismounted';
import { Palette } from 'color-thief-react';
import { HoverContext } from '../../context/HoverContext';
import HoverIntent from '../../utils/hoverIntent';
import className from '../../utils/className';
import styles from './accordionItem.css';

function AccordionItem({ image, title, path, index }) {
  const [active, setActive] = useState(false);
  const isMounted = useIsMounted();
  const { setHoverEvents, updateMoveEvents } = useContext(HoverContext);

  function getMousePercentage(event) {
    const rect = event.target.getBoundingClientRect();
    const relY = event.clientY - rect.top;
    const relX = event.clientX - rect.left;
    const y = Math.round(relY);
    const x = Math.round(relX);
    const xPercent = Math.round((x / rect.width) * 100);
    const yPercent = Math.round((y / rect.height) * 100);

    return { x: xPercent, y: yPercent };
  }

  function handleMouseEvents(event) {
    if (isMounted.current) {
      if (event.type === 'mouseover') {
        const mouseCoords = getMousePercentage(event);
        updateMoveEvents(mouseCoords);
        setHoverEvents({
          active: true,
          img: image.src,
        });
        setActive(true);
      } else {
        setHoverEvents({
          active: false,
          img: '',
        });
        setActive(false);
      }
    }
  }

  function handleMouseMove(event) {
    if (isMounted.current) {
      const mouseCoords = getMousePercentage(event);

      if (active) {
        setHoverEvents({
          active: true,
          img: image.src,
        });
        setActive(true);

        updateMoveEvents(mouseCoords);
      }
    }
  }

  return (
    <li {...className(styles.item, active && 'itemActive')} data-index={index}>
      <HoverIntent
        onMouseOver={handleMouseEvents}
        onMouseOut={handleMouseEvents}
        sensitivity={1}
        interval={50}
        timeout={0}
      >
        <div>
          <Palette
            src={image.placeholder}
            crossOrigin="anonymous"
            format="rgbString"
            colorCount={2}
          >
            {({ data, loading }) => {
              if (loading) return;
              return (
                <div
                  style={{ backgroundColor: data[0] }}
                  className={styles.content}
                  onMouseMove={handleMouseMove}
                >
                  <Link
                    className={styles.link}
                    role="link"
                    to={{
                      pathname: path,
                      state: {
                        photoIndex: index,
                        scrollY: window.scrollY,
                      },
                    }}
                  >
                    <img
                      {...className(styles.img, 'lazyload')}
                      data-sizes="auto"
                      src={image.placeholder}
                      data-srcset={image.srcSet}
                      alt={title}
                    />
                  </Link>
                </div>
              );
            }}
          </Palette>
        </div>
      </HoverIntent>
    </li>
  );
}

export default AccordionItem;
