import React from 'react';
import { Link } from 'react-router-dom';
import { Header, Footer, SEO } from '../../components';
import styles from './noMatch.css';

function NoMatch() {
  return (
    <div className={styles.wrapper}>
      <SEO title="Page Not Found" />
      <Header />
      <span className={styles.text}>
        Sorry, no photos here. Return <Link to={{ pathname: '/' }}>Home</Link>.
      </span>
      <Footer />
    </div>
  );
}

export default NoMatch;
